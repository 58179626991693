<template>
  <div>
    <fieldsRows
      v-if="localStoredValue.target"
      :templateContent="templateContent"
      @field-value-changed="updateFieldValue(...arguments)"
      @ip-changed="updateStoredValue"
      :valueLine="{
        ...localStoredValue,
        useDefaultDnsServer: localStoredValue.target.useDefaultDnsServer,
      }"
    ></fieldsRows>
     <explorerCollectStatistics v-model="statistic" :result="result">
    </explorerCollectStatistics>
  </div>
</template>
<script>
import fieldsRows from "@/commonComponents/fieldsRows.vue";
import teststepDefault from "@/cfg/teststep-default.json";
 import explorerCollectStatistics from "@/components/legacy/explorerCollectStatistics.vue";

export default {
  components: {
    fieldsRows,
    explorerCollectStatistics
  },
  data() {
    return {};
  },
  props: ["result", "stepStoredValue", "stepType", "stepID"],
  computed: {
    statistic: {
      get() {
        return this.stepStoredValue.statistic;
      },
      set(newVal) {
        console.log(newVal);
        this.$set(this.stepStoredValue, "statistic", newVal);
        this.$emit("input", this.stepStoredValue);
      },
    },
    localStoredValue: {
      get() {
        if (
          typeof this.stepStoredValue == "undefined" ||
          typeof this.stepStoredValue.startWithinMinutes == "undefined" ||
          typeof this.stepStoredValue.res == "undefined"
        ) {
          this.$emit("stored-value-changed", {
            ...teststepDefault[this.stepType],
            // res: {
            //   onError: {
            //     action: "stopTest",
            //     result: "error",
            //   },
            //   eval: [],
            // },
            // parameters: {
            //   count: "4",
            //   ttl: "64",
            //   packet: "56",
            // },
             statistic: {
                collectTime: "monitoring_time",
              },
          });
        }
        return this.stepStoredValue;
      },
      set(newVal) {
        this.$emit("stored-value-changed", newVal);
      },
    },
    templateContent() {
      let templateContent = {
        fields: {
          timeLine: {
            type: "line",
            fields: {
              startWithinMinutes: {
                type: "number",
                name: "Start Within Minutes",
                fieldAttrInput: {
                  class: "",
                },
                colAttrs: { style: "max-width:170px", class: "" },
              },
              finishedWithinMinutes: {
                type: "number",
                name: "Finished Within Minutes",
                fieldAttrInput: {
                  class: "",
                },
                colAttrs: { style: "max-width:170px", class: "" },
              },
              maxDuration: {
                type: "number",
                name: "Maximum Duration (s)",
                fieldAttrInput: {
                  class: "",
                },
                colAttrs: { style: "max-width:170px", class: "" },
              },
            },
          },

          /* target: {
            type: "text",
            name: "Target",
            fieldAttrInput: {
              class: "required IPDomain",
            },
          },*/
          parameters: {
            type: "text",
            inputFieldFormatter: "rttParameters",
          },
          target: {
            type: "text",
            inputFieldFormatter: "rttTargetFormatter",
          },

          res: {
            type: "text",
            inputFieldFormatter: "explorerResult",
          },
        },
        stepType: this.stepType,
        stepID: this.stepID,
        disableAllInputs: this.result.disableAllInputs,
        //contentID: this.stepType + "_" + this.stepID,
        contentID: this.stepType,
      };

      return templateContent;
    },
  },
  methods: {
    // emitUpdateFields(info) {
    //   this.$emit("updated-fields", info);
    // },
    updateFieldValue(field, value) {
      // console.log(field, value);
      let localStoredStep = { ...this.stepStoredValue };
      if (field == "useDefaultDnsServer") {
        this.$set(localStoredStep.target, field, value);
      } else {
        this.$set(localStoredStep, field, value);
      }
      this.$emit("stored-value-changed", localStoredStep);
    },
    updateStoredValue(value) {
      let localStoredStep = { ...this.stepStoredValue };
      this.$set(localStoredStep.target, "ip", value);

      this.$emit("stored-value-changed", localStoredStep);
    },
  },
};
</script>


